import api from '@/api/api'
import config from '@/config'
export async function getBurnImage(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/images/${data.imageId}/burn`,
    data.payload,
  )
}
export async function getFloodImage(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/images/${data.imageId}/flood`,
    data.payload,
  )
}
export async function getArea(data) {
  return await api.post(`${config.upload_url}/calculate-disaster-area`, data.params)
}


