<template>
  <v-layout class="fill-height overflow-hidden" column>
    <v-layout fill-height>
      <div
        style="flex: none; height: fit-content; position: relative"
        class="AOI-menu custom-card-bg-gradient"
        height="100%"
        min-width="340"
        width="340"
        v-if="leftMenu"
      >
        <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
          <v-icon size="26">icon-chevrons_left</v-icon>
        </v-btn>
        <v-layout class="pt-2 overflow-y-hidden" style="flex: 1; min-height: 200px; min-width: 340px; height: 100vh">
          <v-card class="AOI-menu custom-card-bg-1" height="100%" width="100%">
            <v-layout class="pa-3 pt-5" column>
              <div style="flex: none; min-height: 0; width: 100%">
                <v-layout align-center class="fill-height px-3">
                  Data Analytic
                  <v-spacer />
                  <v-btn icon @click="showData = !showData">
                    <v-icon v-if="showData">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-layout>
              </div>
              <v-divider />
              <v-layout v-show="showData" align-center class="fill-height">
                <v-card class="custom-card-bg-gradient" width="100%" height="100%" min-height="100">
                  <v-layout align-center column justify-center>
                    <div class="text-gradient" style="font-size: 2rem; font-weight: bold">FLOOD AREA</div>
                    <div style="position: relative; min-height: 50px">
                      <div style="font-size: 24px" v-html="floodArea"></div>
                      <v-overlay :value="loading" absolute>
                        <v-progress-circular indeterminate size="32"></v-progress-circular>
                      </v-overlay>
                    </div>
                  </v-layout>
                </v-card>
              </v-layout>
            </v-layout>
            <v-layout class="fill-height pa-3 pt-5" column>
              <div style="flex: none; min-height: 0; width: 100%">
                <v-layout class="fill-height">
                  Detail
                  <v-spacer />
                  <v-btn
                    :disabled="!this.currentAOI"
                    color="primary"
                    class="mr-2"
                    height="38"
                    min-height="0"
                    min-width="0"
                    small
                    width="38"
                    @click="isCreating = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!this.currentAOI"
                    color="error"
                    height="38"
                    min-height="0"
                    min-width="0"
                    small
                    width="38"
                    @click="deleteAllItem()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-layout>
              </div>
              <div class="pt-3 overflow-y-hidden" style="flex: 1">
                <v-layout class="fill-height overflow-y-hidden" style="width: 100%">
                  <v-data-table
                    :headers="headers"
                    :items="features"
                    :loading="dataLoading"
                    class="hide-per-page table-small-text fill-height"
                    fixed-header
                    height="100%"
                    hide-default-footer
                    style="width: 100%"
                  >
                    <template #[`item.area`]="{ item }">
                      <div v-html="item.area"></div>
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <v-btn icon @click="deleteItem(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-layout>
              </div>
            </v-layout>
          </v-card>
        </v-layout>
      </div>
      <div style="width: 100%; height: 90%" class="pl-2">
        <v-layout>
          <v-col ref="AOI" class="pl-0" cols="12" lg="4">
            <select2
              v-model="currentAOI"
              :items="listAOI"
              dense
              hide-details
              item-text="name"
              item-value="uuid"
              label="Select AOI"
              outlined
              placeholder="AOI"
              return-object
              @change="getService"
            />
          </v-col>
          <v-col ref="source" cols="12" lg="4">
            <v-select
              v-model="source"
              :disabled="!Object.keys(service).length"
              :items="sources"
              :loading="loading"
              dense
              hide-details
              item-text="name"
              label="Select source"
              outlined
              placeholder="Source"
              return-object
            />
          </v-col>
          <v-col ref="result" cols="12" lg="4">
            <v-select
              v-model="result"
              :disabled="!source"
              :items="data"
              :loading="loading"
              dense
              hide-details
              item-text="date"
              label="Date"
              outlined
              placeholder="Date"
              return-object
              @change="getLayers"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="0"></v-col>
        </v-layout>
        <div class="fill-height" style="position: relative">
          <v-layout
            class="pb-2;"
            style="flex: 1; position: absolute; left: 20px; top: 12px; z-index: 2"
            v-if="layerControl"
          >
            <v-btn
              v-show="layerControl"
              color="bgContainer"
              fab
              small
              style="position: absolute; top: 45%; right: -20px; z-index: 10"
              @click.native.stop="layerControl = false"
            >
              <v-icon small>icon-chevrons_left</v-icon>
            </v-btn>
            <v-card
              class="AOI-menu custom-card-bg-gradient"
              height="100%"
              width="100%"
              style="height: calc(100vh - 200px)"
            >
              <LayerControl
                ref="layerControl"
                :current-layers.sync="layers"
                :isDrawing.sync="isDraw"
                :removable="false"
                comparable
                map-control
                transparent
                @cancel="cancelCreate"
                @changeCompare="show => $refs.map.changeCompare(show)"
                @changeToSyncMap="status => ($refs.map.mapSync = status)"
                @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
                @changeVisibleLeftLayer="(layer, isSync) => $refs.map.changeVisibleLeftLayer(layer.id)"
                @createLayer="createLayer"
                @reloadMap="() => $refs.map.reloadMap()"
                @removeAllLayer="() => $refs.map.removeAllLayer()"
                @removeLayer="layerId => $refs.map.removeLayer(layerId)"
                @saveLayer="saveLayer"
                @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
              />
            </v-card>
          </v-layout>
          <div style="position: absolute; bottom: 12px; left: 12px; z-index: 2">
            <v-btn v-if="!leftMenu" color="bgContainer" fab class="mr-2" @click="leftMenu = true">
              <v-icon>icon-menu</v-icon>
            </v-btn>
            <v-btn v-if="!layerControl" color="bgContainer" fab @click="layerControl = true">
              <v-icon>icon-layers</v-icon>
            </v-btn>
          </div>

          <Map
            ref="map"
            :current-layers.sync="layers"
            :drawType.sync="drawType"
            :isCreateText="displayType === 'text'"
            :isDraw="isCreating || isDraw"
          />
          <v-btn-toggle v-if="isCreating" style="position: absolute; top: 12px; right: 60px; z-index: 2">
            <v-btn small width="120" @click="getFlood">
              <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
              Submit
            </v-btn>
            <v-btn small width="120" @click="isCreating = false">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              close
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </v-layout>
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import Select2 from '@/components/Select2/Select2.vue'
import { mapState } from '@/store/ults'
import Map from '@/components/Map'
import { getDetailAOI } from '@/api/aoi-api'
import { getOrder } from '@/api/order'
import { getImageResult } from '@/api/change-detection-api'
import { getFloodImage, getArea } from '@/api/detect-area-api'
import AreaTransform from '@/utils/convertArea'
import utils from '@/utils/genUUID'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import numberFormat from '@/utils/comma'
import booleanIntersects from '@turf/boolean-intersects'

export default {
  components: { CardMapView, Select2, Map, LayerControl },
  data() {
    return {
      service: {},
      showData: true,
      layerControl: true,
      sources: [],
      source: undefined,
      currentAOI: undefined,
      loading: false,
      data: [],
      result: undefined,
      leftMenu: true,
      dataLoading: false,
      geometry: {},
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Flood Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Action',
          align: 'center',

          value: 'actions',
        },
      ],
      features: [],
      layers: [],
      drawType: '',
      displayType: '',
      isCreating: false,
      isDraw: false,
      currentImage: undefined,
      firstLoad: true,
      floodArea: undefined,
    }
  },
  computed: { ...mapState('AOI', ['AOIs', 'listAOI']) },
  watch: {
    leftMenu() {
      this.$refs.map.reSize()
    },
    showData() {
      this.$refs.map.reSize()
    },
    layers(val) {
      let removedLayer = []
      this.features.forEach(vector => {
        if (!this.layers.some(layer => layer.id === vector.id)) {
          removedLayer.push(vector)
        }
      })
      removedLayer.forEach(layer => {
        layer.display = false
        let index = this.features.findIndex(vector => vector.id === layer.id)
        if (index >= 0) this.features.splice(index, 1)
      })
      if (!this.layers.some(layer => layer.id === this.currentAOI.uuid)) this.currentAOI = undefined
    },
  },
  async mounted() {
    await this.getListAOI()
    this.elements = [
      {
        ref: this.$refs.AOI,
        id: 'AOI',
        title: 'Getting Started',
        text:
          this.listAOI.length > 0
            ? 'Select an AOI to get results'
            : "You don't have any AOIs with results. Please submit an order.",
        position: 'bottom',
        buttons:
          this.listAOI.length > 0
            ? undefined
            : [
                {
                  text: 'Submit Order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
                  },
                },
              ],
      },
      {
        ref: this.$refs.source,
        id: 'source',
        title: 'Getting Started',
        text: 'Select a source of result',
        position: 'bottom',
      },
      {
        ref: this.$refs.result,
        id: 'result',
        title: 'Getting Started',
        text: this.data.length > 0 ? 'Select a result to show' : 'No result found, please check your order',
        position: 'bottom',
        buttons:
          this.data.length > 0
            ? undefined
            : [
                {
                  text: 'Check order',
                  action: () => {
                    this.$refs.tourGuide.completeTour()
                    this.$router.push({
                      name: 'orders',
                      query: {
                        aoiId: this.currentAOI.uuid,
                        service: this.service.service_id,
                        source: this.source,
                      },
                    })
                  },
                },
              ],
      },
    ]
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  methods: {
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },

    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      this.$refs.map.createLayer(name, type)
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isDraw = true
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        await this.getInfoAOI()
        this.$refs.map.submitZoom(this.currentAOI.bbox)
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.service = res.data[0]
        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))
        this.source = this.sources[0]
        if (this.source) await this.getListResult()
        else this.firstLoad = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        if (this.firstLoad) this.currentAOI = this.AOIs[0]
        if (!this.currentAOI) this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.geometry = res.data.geojson.features[0].geometry
        this.$refs.map.addGeoJsonToMap(res.data.name, res.data.geojson, 'yellow', res.data.uuid, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    resetData() {
      this.source = undefined
      this.data = []
      this.result = undefined
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.result = undefined
        this.data = []
        const res = await getImageResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        if (this.firstLoad) this.result = this.data[0]
        if (this.result) this.getLayers()
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    deleteItem(item) {
      this.features = this.features.filter(feature => feature.id !== item.id)
      this.$refs.map.removeLayer(item.id)
    },
    deleteAllItem() {
      this.features.forEach(feature => {
        this.$refs.map.removeLayer(feature.id)
      })
      this.features = []
    },
    async getLayers() {
      try {
        this.loading = true
        this.floodArea = undefined
        let removeLayers = this.layers.filter(layer => layer.id !== this.currentAOI.uuid)
        removeLayers.forEach(layer => {
          this.$refs.map.removeLayer(layer.id)
        })
        const res = await getFloodImage({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          imageId: this.result.uuid,
          payload: { source: this.source, includes: ['path'] },
        })
        this.floodArea = AreaTransform.transformUnit(res.data.area)
        this.currentImage = res.data.data[0]
        res.data.data.forEach((val, index) => {
          if (index === res.data.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: index === res.data.data.length - 1,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.addImageToMap(image)
          } else {
            console.log(val.name)
            this.$refs.map.addVectorTiles({
              display: index === res.data.length - 1,
              data: val.tile_info,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              paint: VectorStyle.getStyle(val.tile_info.styles),
              name: val.name,
              id: val.name,
              type: val.tile_info.styles.type,
            })
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    convertUnit(area) {
      if (this.floodArea.split(' ')[1] === 'km<sup>2</sup>')
        return numberFormat.numberWithCommas(Math.round((area / 1000000) * 100) / 100)
      else if (this.floodArea.split(' ')[1])
        return numberFormat.numberWithCommas(Math.round((area / 10000) * 100) / 100)
      else return numberFormat.numberWithCommas(Math.round(area * 100) / 100)
    },
    async getFlood() {
      try {
        this.loading = true
        let params = {
          file_path: this.currentImage.path,
          geometry: {},
          service_name: this.$route.meta.service,
        }
        let features = this.$refs.map.getAllDrawFeature()
        if (!booleanIntersects(this.geometry, features[0].geometry)) {
          this.$store.commit('message/SHOW_ERROR', 'polygon is not in AOI')
          this.$refs.map.resetDraw()
          this.isCreating = false
          return
        }

        params.geometry = features[0].geometry
        const res = await getArea({ params: params })
        let name = 'Polygon-' + this.features.length
        let id = utils.getUUID()
        this.features.push({
          name: name,
          area: `${this.convertUnit(res.data.data.flood_area)} ${this.floodArea.split(' ')[1]}`,
          id: id,
        })
        this.$refs.map.resetDraw()
        let geojson = {
          type: 'FeatureCollection',
          features: [features[0]],
        }
        this.$refs.map.addGeoJsonToMap(name, geojson, 'red', id, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.isCreating = false
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
